import React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { FileUploaderWrapper } from './file-uploader.styles'
import { IFileUploader } from './file-uploader.types'
import { savingUploadImageBannerHandler } from '../../views/banners-management/service'
import Loading from '../Loading'
import { colorThemeProvider } from '../../theme/themeProvider'
import { DevicesEnum, RecommendedSize } from '../../views/banners-management/components/midia-container/midia-container.types'
import { Destiny } from '../../views/banners-management/banners-management.types'

const enabledSize = {
	width: 1560,
	height: 340
}

const FileUploader = ({
	srcImage,
	handleGetSrc,
	acceptExtensios = '',
	canDelete = true,
	isDisabled = false,
	deviceSelected,
	destinySelected
}: IFileUploader) => {
	const [selectedImage, setSelectedImage] = React.useState<string>()
	const [isLoading, setIsLoading] = React.useState(false)
	const [recommendedSize, setRecommendedSize] = React.useState('')
	// const [selectedImageBinary, setSelectedImageBinary] =
	// 	React.useState<File | null>(null)
	const ref = React.useRef<HTMLInputElement>(null)

	React.useEffect(() => {
		setSelectedImage(srcImage)
	}, [srcImage])

	const handleSelectImage = () => {
		if (ref.current !== null) {
			ref.current.click()
		}
	}

	const handleDataChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const imageFile = event.target.files?.[0]

		if (imageFile) {
			setIsLoading(true)
			const uploadImageBannerResponse =
				await savingUploadImageBannerHandler({
					file: imageFile,
					fileName: `pn-img-${Number(new Date())}`
				}).finally(() => {
					setIsLoading(false)
				})
			var fileReader = new FileReader();
			fileReader.onload = function () {
				var img = new Image();
				img.onload = function () {
					if (img.width !== enabledSize.width && img.height !== enabledSize.height) {
						// eslint-disable-next-line no-restricted-globals
						const forceSave = confirm('Imagem diferente do tamanho recomendado(1560x340), deseja prosseguir?')
						if (forceSave) {
							setSelectedImage(uploadImageBannerResponse.url)
							handleGetSrc(uploadImageBannerResponse.url)
						}
					}
				};
				img.src = fileReader.result as string;
			};
			fileReader.readAsDataURL(imageFile);
		}
	}

	const handleDeleteImage = () => {
		setSelectedImage('')
		handleGetSrc('')
	}

	React.useEffect(() => {
		if (deviceSelected?.value === DevicesEnum.APP) {
			if (destinySelected?.value === Destiny.HOME) return setRecommendedSize(`Recomendado: ${RecommendedSize.APPHOME} PX, até 300 KB`)
			if (destinySelected?.value === Destiny.LOGIN) return setRecommendedSize(`Recomendado: ${RecommendedSize.APPLOGIN} PX, até 300 KB`)
		} else if (deviceSelected?.value === DevicesEnum.WEB) {
			setRecommendedSize('')
			if (destinySelected?.value === Destiny.HOME) return setRecommendedSize(`Recomendado: ${RecommendedSize.WEBHOME} PX, até 300 KB`)
			if (destinySelected?.value === Destiny.LOGIN) return setRecommendedSize(`Recomendado: ${RecommendedSize.WEBLOGIN} PX, até 300 KB`)
		} else {
			setRecommendedSize('')
		}
	}, [deviceSelected, destinySelected])

	return (
		<FileUploaderWrapper>
			{!selectedImage ? (
				<div
					className="upload-file"
					onClick={handleSelectImage}
					style={{
						backgroundColor: isDisabled ? colorThemeProvider.actionColor : colorThemeProvider.whiteColor,
						color: isDisabled ? colorThemeProvider.whiteColor : colorThemeProvider.primaryColor
					}}
				>
					{isLoading ? (
						<Loading color="red" />
					) : (
						<>
							<input
								onChange={handleDataChange}
								ref={ref}
								type="file"
								accept={acceptExtensios}
								disabled={isDisabled}
							/>
							<FileUploadIcon />
							<p className="title-upload-image">
								Arraste ou selecione a imagem
							</p>
							<p className="sub-title-upload-image">
								{recommendedSize}
							</p>
						</>
					)}
				</div>
			) : (
				<>
					<div className="gradient"></div>
					<div
						className="img"
						style={{
							backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%), url(${selectedImage})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							backgroundPosition: 'center'
						}}
					></div>
					{canDelete && (
						<div className="image-info-wrapper">
							<div className="image-info">
								<p className="image-detail-name">
									push_image.jpg
								</p>
							</div>
							<DeleteOutlinedIcon
								className="delete-image"
								style={{ color: '#ffff' }}
								onClick={() => handleDeleteImage()}
							/>
						</div>
					)}
				</>
			)}
		</FileUploaderWrapper>
	)
}

export default FileUploader
